import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useTransactionDetails from "./useTransactionDetails";
import EmptyData from "../../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import Style from "./transaction.module.scss";

const TransactionDetails = () => {
  const {
    TransactionData,
    applicantTransactionState,
    isFetching,
    applicantDetails,
    approveState,
    rejectState,
    handleYesReject,
    handleYesApprove,
    handleRejectApplicant,
    handleCloseApproveModal,
    handleApproveApplicant,
    getRow,
  } = useTransactionDetails();

  return (
    <>
      <div className="pro-pt-3">
        <div className="row gx-2">
          <div className="col">
            <HeadingGroup title={"Transactions"} className={`pro-mb-4`} />
          </div>
          {applicantDetails?.status === 2 && (
            <div className="col-auto pro-d-flex pro-gap-2">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 pro-pt-3 `}
                onClick={handleRejectApplicant}
              >
                <span class="material-symbols-outlined">unpublished</span>
                <span>Reject</span>
              </button>
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 pro-pt-3 `}
                onClick={handleApproveApplicant}
              >
                <span class="material-symbols-outlined">data_check</span>
                <span>Approve</span>
              </button>
            </div>
          )}
        </div>

        <div className="col-auto pro-pt-3 pro-pb-5">
          {TransactionData?.data?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={TransactionData?.data?.data || []}
              uniqueID={"id"}
              fields={TransactionData?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              getRow={getRow}
              clear={applicantTransactionState?.clearSelection}
              loading={isFetching}
              perpage={applicantTransactionState?.currentPageSize}
              assignable={false}
              deletable={true}
              editable={true}
              showCheckBox={false}
            />
          )}
        </div>

        <ModalLayout
          show={approveState || rejectState}
          centered={"true"}
          handleClose={handleCloseApproveModal}
          propStyle={{
            root: Style.confirmation_modal,
          }}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure want to ${
                approveState ? "confirm" : "reject"
              } this applicant ?`}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleCloseApproveModal}
              submitText={"Yes"}
              submitAction={approveState ? handleYesApprove : handleYesReject}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default TransactionDetails;
