import { useEffect, useMemo } from "react";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";
import { useLocation, useNavigate } from "react-router-dom";
import useRoutes from "../../../routes/useRoutes";

const useCommonLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const globalState = useSelector((state) => state.global);
  const { menuDrawer } = useSelector((state) => state.global);
  const { checkIfActiveRoute } = useRouteUtils();

  const {
    allowedDashboardComponent,
    allowedReportComponent,
    allowedUserComponent,
    allowedSettingsComponent,
  } = useRoutes();

  useEffect(() => {
    if (
      globalState?.isLogged === true &&
      (allowedDashboardComponent?.length > 0 ||
        allowedUserComponent?.length > 0 ||
        allowedReportComponent?.length > 0)
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedUserComponent?.length > 0) {
        navigate(`/${allowedUserComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedReportComponent?.length > 0) {
        navigate(`/${allowedReportComponent?.map((item) => item?.path)?.[0]}`);
      } else {
        navigate(
          `/${allowedSettingsComponent?.map((item) => item?.path)?.[0]}`
        );
      }
    }

    //eslint-disable-next-line
  }, [
    allowedDashboardComponent,
    allowedReportComponent,
    allowedUserComponent,
    allowedSettingsComponent,
  ]);

  const navigations = [
    {
      label: "Dashboard",
      link: "/dashboard",
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Report",
      link: "/report/booking-report",
      isButton: false,
      active: checkIfActiveRoute("/report"),
    },
    {
      label: "User",
      link: "/user/user-list",
      isButton: false,
      active: checkIfActiveRoute("/user"),
    },
    {
      label: "Settings",
      link: "/settings/appearance",
      isButton: false,
      active: checkIfActiveRoute("/settings"),
    },
  ];

  const isAdmin = localStorage.getItem("is_admin");
  const isSuperAdmin = localStorage.getItem("is_superadmin");

  if (isAdmin === "false" && isSuperAdmin === "false") {
    const userIndex = navigations.findIndex((item) => item?.label === "User");
    if (userIndex !== -1) {
      navigations.splice(userIndex, 1);
    }
  }

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  const topMenuPermission = useMemo(() => {
    let menus = ["Dashboard", "Report", "User", "Settings"];
    return navigations
      .filter((menu) => menus?.includes?.(menu?.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    handleGoBack,
    globalState,
    menuDrawer,
  };
};

export default useCommonLayout;
