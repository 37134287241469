import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBookingReport from "./useBookingReport";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import AddReport from "./AddReport";

const BookingReport = () => {
  const {
    isLoading,
    isFetching,
    bookingReportList,
    paginationOptions,
    bookingReportState,
    refetch,
    handleCreateClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleDeleteModal,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
  } = useBookingReport();
  return (
    <>
      <HeadingGroup
        title={"Booking Report"}
        className={`pro-mb-4`}
        buttonTitle={"Upload File"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                bookingReportState?.search === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: bookingReportState?.search }}
              showActions={false}
            />
          </div>
        </div>
        {bookingReportList?.data?.result?.data !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={bookingReportList?.data?.data || []}
              uniqueID={"_id"}
              fields={bookingReportList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              clear={bookingReportState?.clearSelection}
              loading={isFetching}
              perpage={bookingReportState?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDeleteModal}
              deletable={true}
              editable={true}
              showCheckBox={false}
            />

            {bookingReportList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={bookingReportState?.currentPage}
                totalPageCount={Math.ceil(
                  bookingReportList?.data?.total_count /
                    bookingReportState.currentPageSize
                )}
                onPageChange={handlePagination}
                defaultValue={paginationOptions?.filter(
                  (item) => item.value === bookingReportState.currentPageSize
                )}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}

        <OffCanvasLayout
          show={bookingReportState?.showCreateModal}
          handleClose={handleCreateClick}
          title={
            bookingReportState?.isEdit
              ? "Update Booking Report"
              : "Booking Report"
          }
          backdrop={"static"}
        >
          <AddReport handleCreateClick={handleCreateClick} refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default BookingReport;
