import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  staffData: [],
  imagePreview: "",
  imageName: "",
  editStaffData: {},
  //handling states in document section
  modalType: 0,
  approveAll: false,
  rejectAll: false,
  showPdfModal: false,
  showImageModal: false,
  documentFrom: "",
};

const applicantsDocumentSlice = createSlice({
  name: "applicantDocument",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = applicantsDocumentSlice.actions;
export default applicantsDocumentSlice.reducer;
