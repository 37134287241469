import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils";
import { useGetApplicantDetailQuery } from "../../../../store/queries/Report";
import { updateConfig } from "../../../../store/slices/Report/Applicant/applicantsSlice";
import { useDispatch, useSelector } from "react-redux";

const useApplicantDetailLayout = () => {
  const { applicant_id } = useParams();
  const dispatch = useDispatch();
  const { showDetailseditModal } = useSelector((state) => state.applicants);
  const { checkIfActiveRoute } = useRouteUtils();
  const activeProfile = applicant_id ?? sessionStorage.getItem("active");
  const location = useLocation();

  const {
    data: profileData,
    isFetching,
    refetch,
  } = useGetApplicantDetailQuery({
    applicant_id: activeProfile,
  });

  useEffect(() => {
    if (profileData?.result) {
      dispatch(
        updateConfig((state) => {
          state.applicantDetails = profileData?.result;
        })
      );
    }
    //eslint-disable-next-line
  }, [profileData?.result]);

  const label = {
    first_name: "First Name",
    last_name: "last Name",
    sender_email: "Email",
    sender_phone: "Phone Number",
    sender_address1: "Address 1",
    sender_address2: "Address 2",
    sender_state: "State",
    sender_zip: "Zip Code ",
    amount_to: "Amount to",
    amount_from: "Amount from",
    tax_amount: "Tax Amount",
    currency_to: "Currency to",
  };

  const value = {
    first_name: ` ${profileData?.result?.first_name ?? "Not specified"}`,
    last_name: ` ${profileData?.result?.last_name ?? "Not specified"}`,
    sender_email: ` ${profileData?.result?.sender_email ?? "Not specified"}`,
    sender_phone: `${profileData?.result?.sender_phone ?? "Not Specified"}`,
    sender_address1: `${
      profileData?.result?.sender_address1 ?? "Not Specified"
    }`,
    sender_address2: `${
      profileData?.result?.sender_address2 ?? "Not Specified"
    }`,

    sender_state: ` ${profileData?.result?.sender_state ?? "Not specified"}`,
    sender_zip: ` ${profileData?.result?.sender_zip ?? "Not specified"}`,
    amount_to: `${
      parseFloat(profileData?.result?.amount_to).toLocaleString() ??
      "Not specified"
    }`,
    amount_from: `${
      parseFloat(profileData?.result?.amount_from).toLocaleString() ??
      "Not specified"
    }`,
    tax_amount: `${
      parseFloat(profileData?.result?.tax_amount).toLocaleString() ??
      "Not specified"
    }`,
    currency_to: `${profileData?.result?.currency_to ?? "Not specified"}`,
  };

  const applicantDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const navigation = [
    {
      label: "Activity",
      title: "Activity",
      link: `/report/applicants/activity-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/report/applicants/activity-details/${activeProfile}`,
        true
      ),
    },
    {
      label: "Transaction Details",
      title: "Transaction Details",
      link: `/report/applicants/transaction-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/report/applicants/transaction-details/${activeProfile}`,
        true
      ),
    },
    {
      label: "Document Details",
      title: "Document Details",
      link: `/report/applicants/document-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/report/applicants/document-details/${activeProfile}`
      ),
    },
  ];

  const branchTabPermission = useMemo(() => {
    // let menus = savedBranchPermissions?.flatMap?.((menu) => [menu.label]);
    return (
      navigation
        // .filter((menu) => menus?.includes?.(menu.title))
        .map((menu) => {
          return {
            ...menu,
          };
        })
    );
    //eslint-disable-next-line
  }, [location.pathname]);

  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailseditModal = true;
      })
    );
  };

  const closeDetailsEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailseditModal = false;
      })
    );
  };

  return {
    profileData,
    isFetching,
    applicantDetails,
    navigation: branchTabPermission,
    activeProfile,
    showDetailseditModal,
    refetch,
    closeDetailsEditModal,
    handleEditClick,
  };
};

export default useApplicantDetailLayout;
