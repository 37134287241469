import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route,
} from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Index from "../pages";
import Report from "../components/Report";
import BookingReport from "../components/Report/BookingReport";
import Transactions from "../components/Report/Transactions";
import Applicants from "../components/Report/Applicants";
import DashboardListing from "../components/Dashboard/DashboardListing";
import AuthModule from "../components/Auth";
import Login from "../components/Auth/Login";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ChangePassword from "../components/Auth/ChangePassword";
import User from "../components/User";
import UserList from "../components/User/UserList";
import Settings from "../components/Settings";
import Appearance from "../components/Settings/Appearance";
import ApplicantDetialLayout from "../components/Report/Applicants/ApplicantsDetailLayout";
import TransactionDetails from "../components/Report/Applicants/TransactionDetails";
import DocumentDetails from "../components/Report/Applicants/DocumentDetails";
import ActivityDetails from "../components/Report/Applicants/ActivityDetails";
import PendingApplicants from "../components/Report/PendingApplicants";
import RefundTransaction from "../components/Report/RefundTransaction";
import ProcessedApplicants from "../components/Report/ProcessedApplicants";
import ExcessCredited from "../components/Report/ExcessCredited";

const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="/report" element={<Report />}>
          <Route path="booking-report" element={<BookingReport />} />
          <Route path="transaction" element={<Transactions />} />
          <Route path="applicants" element={<Applicants />} />
          <Route path="pending-applicants" element={<PendingApplicants />} />
          <Route path="refunds" element={<RefundTransaction />} />{" "}
          <Route
            path="processed-applicants"
            element={<ProcessedApplicants />}
          />
          <Route path="excess-credited" element={<ExcessCredited />} />
        </Route>

        <Route path="report/applicants" element={<ApplicantDetialLayout />}>
          <Route
            path="activity-details/:applicant_id"
            element={<ActivityDetails />}
          ></Route>
          <Route
            path="transaction-details/:applicant_id"
            element={<TransactionDetails />}
          ></Route>
          <Route
            path="document-details/:applicant_id"
            element={<DocumentDetails />}
          ></Route>
        </Route>

        <Route path="/user" element={<User />}>
          <Route path="user-list" element={<UserList />} />
        </Route>
        <Route path="/settings" element={<Settings />}>
          <Route path="appearance" element={<Appearance />} />
        </Route>
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
