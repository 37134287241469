import { useSelector, useDispatch } from "react-redux";
import { useGetRefundTransactionQuery } from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/refundTransactionSlice";

const useRefundTransaction = () => {
  const dispatch = useDispatch();
  const refundTransactionState = useSelector(
    (state) => state.refundTransaction
  );
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: refundTransactionList,
    isLoading,
    isFetching,
  } = useGetRefundTransactionQuery({
    search: refundTransactionState?.search,
    //     fields: refundTransactionState?.fields,
    page_number: refundTransactionState?.currentPage,
    paginator_limit: refundTransactionState?.currentPageSize,
    order_by: {
      field: refundTransactionState?.sortBy || "",
      order: refundTransactionState?.sortOrder || "",
    },
    //     filter: {
    //       status: refundTransactionState?.currentFilter,
    //     },
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };

  const handleSort = (label) => {
    if (refundTransactionState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            refundTransactionState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    refundTransactionList,
    refundTransactionState,
    isLoading,
    paginationOptions,
    isFetching,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  };
};

export default useRefundTransaction;
