import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useUser = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "User",
      link: "/user/user-list",
      icon: <span className="material-symbols-outlined x4">group</span>,
      active: checkIfActiveRoute("/user/user-list", true),
    },
  ];
  //   const drawerMenuPermission = useMemo(() => {
  //     let menus = ["Booking Report", "Transaction", "Applicants"];
  //     return drawerMenu
  //       ?.filter((menu) => {
  //         return menus?.includes?.(menu?.title);
  //       })
  //       ?.map((menu) => {
  //         return {
  //           ...menu,
  //         };
  //       });
  //     //eslint-disable-next-line
  //   }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenu };
};

export default useUser;
