import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("task_type")}&${getParams(
          "user_type_sort"
        )}&${getParams("sort")}&sort=${params?.sort_order || "desc"}&per_page=${
          params?.page_size || "10"
        }&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const dashboard = createApi({
  reducerPath: "dashboardApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Dashboard", "Dashboard-Table"],
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard`,
      }),
      providesTags: ["Dashboard"],
    }),

    // Transaction component
    getDashboardTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `dashboard`,
      }),
      providesTags: ["Dashboard-Table"],
      // keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetDashboardDataQuery, useGetDashboardTableDataQuery } =
  dashboard;
