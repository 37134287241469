import { combineReducers } from "@reduxjs/toolkit";

import globalSlice from "./slices/Global";
import { dashboard } from "./queries/Dashboard";
import { report } from "./queries/Report";
import { user } from "./queries/User";
import bookingReportSlice from "./slices/Report/bookingReportSlice";
import transactionsSlice from "./slices/Report/transactionsSlice";
import applicantsSlice from "./slices/Report/Applicant/applicantsSlice";
import applicantTransactionSlice from "./slices/Report/Applicant/applicantTransactionSlice";
import applicantsDocumentSlice from "./slices/Report/Applicant/applicantsDocumentSlice";
import userSlice from "./slices/User/userSlice";
import dashboardSlice from "./slices/Dashboard/dashboardSlice";
import pendingApplicantSlice from "./slices/Report/pendingApplicantSlice";
import refundTransactionSlice from "./slices/Report/refundTransactionSlice";
import approvedApplicantSlice from "./slices/Report/approvedApplicantSlice";
import excessCreditedSlice from "./slices/Report/excessCreditedSlice";

const appReducer = combineReducers({
  [dashboard.reducerPath]: dashboard.reducer,
  [report.reducerPath]: report.reducer,
  [user.reducerPath]: user.reducer,
  global: globalSlice,
  bookingReport: bookingReportSlice,
  transactions: transactionsSlice,
  applicants: applicantsSlice,
  applicantTransaction: applicantTransactionSlice,
  applicantDocument: applicantsDocumentSlice,
  user: userSlice,
  dashboardListing: dashboardSlice,
  pendingApplicants: pendingApplicantSlice,
  refundTransaction: refundTransactionSlice,
  approvedApplicants: approvedApplicantSlice,
  excessCredited: excessCreditedSlice,
});

export default appReducer;
