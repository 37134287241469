import { useSelector, useDispatch } from "react-redux";
import { useGetExcessCreditedQuery } from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/excessCreditedSlice";
import moment from "moment";
import { excessReport } from "./api";

const useExcessCredited = () => {
  const dispatch = useDispatch();
  const excessCreditedState = useSelector((state) => state.excessCredited);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: excessCreditedList,
    isLoading,
    isFetching,
  } = useGetExcessCreditedQuery({
    search: excessCreditedState?.search,
    //     fields: excessCreditedState?.fields,
    page_number: excessCreditedState?.currentPage,
    paginator_limit: excessCreditedState?.currentPageSize,
    order_by: {
      field: excessCreditedState?.sortBy || "",
      order: excessCreditedState?.sortOrder || "",
    },
    //     filter: {
    //       status: excessCreditedState?.currentFilter,
    //     },
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };

  const handleSort = (label) => {
    if (excessCreditedState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            excessCreditedState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      amount_from: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      total_received: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      excess_amount: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDownloadClick = () => {
    excessReport().then((res) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(res["data"]);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  return {
    excessCreditedList,
    excessCreditedState,
    isLoading,
    paginationOptions,
    isFetching,
    handleDownloadClick,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  };
};

export default useExcessCredited;
