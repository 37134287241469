import React from "react";
import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useExcessCredited from "./useExcessCredited";

const ExcessCredited = () => {
  const {
    excessCreditedList,
    excessCreditedState,
    isLoading,
    paginationOptions,
    isFetching,
    handleDownloadClick,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  } = useExcessCredited();
  return (
    <>
      <HeadingGroup title={"Excess Credited"} className={`pro-mb-4`} />

      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined">search</span>
              }
              showClearFilters={
                excessCreditedState?.search === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: excessCreditedState?.search }}
              showActions={false}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 pro-pt-3 `}
              onClick={handleDownloadClick}
            >
              <span class="material-symbols-outlined">download</span>
              <span>Download</span>
            </button>
          </div>
        </div>
        {excessCreditedList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={excessCreditedList?.data?.data || []}
            uniqueID={"id"}
            fields={excessCreditedList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={excessCreditedState?.clearSelection}
            loading={isFetching}
            perpage={excessCreditedState?.currentPageSize}
            assignable={false}
            //   handleEdit={handleEditAction}
            //   handleDelete={handleDeleteModal}
            deletable={true}
            editable={true}
            showCheckBox={false}
          />
        )}

        {excessCreditedList?.data?.data?.length > 0 && (
          <div>
            <Pagination
              currentPage={excessCreditedState?.currentPage}
              totalPageCount={excessCreditedList?.data?.number_of_pages}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === excessCreditedState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ExcessCredited;
