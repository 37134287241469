import React from "react";
import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useAddUser from "./useAddUser";
import { capitalizeOnSpace } from "../../../../utils/Functions/table";

const AddUser = ({ refetch }) => {
  const {
    formik,
    isEdit,
    showNewPassword,
    showReEnterPassword,
    handleShowNewPassword,
    handleShowReEnteredPassword,
    getFieldError,
    closeModal,
  } = useAddUser({ refetch });

  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <Input
            label={"First Name"}
            type="text"
            id="firstname"
            name="firstname"
            className={`pro-input lg ${getFieldError("firstname") && " error"}`}
            {...formik.getFieldProps("firstname")}
            onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={getFieldError("firstname")}
            errorMessage={getFieldError("firstname")}
          />
          <Input
            label={"Last Name"}
            type="text"
            id="lastname"
            name="lastname"
            className={`pro-input lg ${getFieldError("lastname") && " error"}`}
            {...formik.getFieldProps("lastname")}
            onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={getFieldError("lastname")}
            errorMessage={getFieldError("lastname")}
          />
          <Input
            label={"Email / Employee ID"}
            type="text"
            id="email_or_emp_id"
            name="email_or_emp_id"
            className={`pro-input lg ${
              getFieldError("email_or_emp_id") && " error"
            }`}
            {...formik.getFieldProps("email_or_emp_id")}
            error={getFieldError("email_or_emp_id")}
            errorMessage={getFieldError("email_or_emp_id")}
            autocomplete="off"
          />
        </div>
        <div className="pro-d-flex">
          <div className="pro-check-box">
            <input
              type="checkbox"
              className="pro-check"
              id="isSuperAdmin"
              name="isSuperAdmin"
              onChange={(e) => {
                formik?.setFieldValue("is_superadmin", e?.target?.checked);
                formik?.setFieldValue(
                  "is_admin",
                  e?.target?.checked === true && false
                );
              }}
              checked={formik?.values?.is_superadmin}
            />
            <label htmlFor="isSuperAdmin" className="pro-check-label">
              Is SuperAdmin
            </label>
          </div>
          <div className="pro-check-box">
            <input
              type="checkbox"
              className="pro-check"
              id="isAdmin"
              name="isAdmin"
              onChange={(e) => {
                formik?.setFieldValue("is_admin", e?.target?.checked);
                formik?.setFieldValue(
                  "is_superadmin",
                  e?.target?.checked === true && false
                );
              }}
              checked={formik?.values?.is_admin}
            />
            <label htmlFor="isAdmin" className="pro-check-label">
              Is Admin
            </label>
          </div>
        </div>

        {!isEdit && (
          <>
            <div className="input-wrap pro-mt-5">
              <label
                htmlFor=""
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                Enter password
              </label>
              <input
                name="user_password"
                id="user_password"
                value={formik.values.user_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                className={`pro-input lg ${
                  formik.errors.user_password &&
                  (formik.touched.user_password ||
                    formik.values.user_password) &&
                  "error"
                }`}
                autocomplete="new-password"
              />
              {showNewPassword ? (
                <button
                  className={`hide-icon pro-me-2`}
                  onClick={(e) => handleShowNewPassword(e)}
                  type="button"
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon  pro-me-2`}
                  onClick={(e) => handleShowNewPassword(e)}
                  type="button"
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
              {formik.errors.user_password &&
                (formik.touched.user_password ||
                  formik.values.user_password) && (
                  <span className="error-text">
                    {formik?.errors?.user_password}
                  </span>
                )}
            </div>

            <div className="input-wrap pro-mt-5 p-re">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Re-enter password
              </label>
              <input
                name="confirm_user_password"
                id="confirm_user_password"
                value={formik.values.confirm_user_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                className={`pro-input lg ${
                  formik.errors.confirm_user_password &&
                  formik.touched.confirm_user_password &&
                  "error"
                }`}
                autocomplete="new-password"
              />

              {showReEnterPassword ? (
                <button
                  className={`hide-icon pro-me-2`}
                  onClick={(e) => handleShowReEnteredPassword(e)}
                  type="button"
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon  pro-me-2`}
                  onClick={(e) => handleShowReEnteredPassword(e)}
                  type="button"
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
              {formik.errors.confirm_user_password &&
                formik.touched.confirm_user_password && (
                  <span className="error-text">
                    {formik?.errors?.confirm_user_password}
                  </span>
                )}
            </div>
          </>
        )}
        <div
          className={`col-12 pro-d-flex pro-mt-5 pro-justify-end ${"offcanvas-footer-sticky-btns"}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            // disabled={hasUpdatePermission === true ? false : true}
            onClick={formik.handleSubmit}
          >
            {!isEdit ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddUser;
