import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import { OffCanvasLayout } from "@wac-ui-dashboard/wac_component_library";
import ProfileDetails from "../../../../components/Global/ProfileView";

const HeaderActions = () => {
  const { globalState, handleLogout, handleSettings, handleViewProfile } =
    useHeaderActions();
  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            {globalState?.profileDetails?.image_url ? (
              <img src={globalState?.imagePreview} alt="staff_image" />
            ) : (
              <span
                className={`material-symbols-outlined x4 ${Style.profile_icon}`}
              >
                {" "}
                account_circle{" "}
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleViewProfile}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3">
              {" "}
              account_circle{" "}
            </span>
            <span className="pro-ms-2">Profile</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleSettings}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> settings </span>
            <span className="pro-ms-2">Settings</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> logout </span>
            <span className="pro-ms-2">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <OffCanvasLayout
        show={globalState.isProfileViewVisible}
        handleClose={handleViewProfile}
        title={"Profile"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop="static"
      >
        <ProfileDetails handleLogout={handleLogout} />
      </OffCanvasLayout>
    </div>
  );
};

export default HeaderActions;
