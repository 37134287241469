import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useProcessedApplicants from "./useProcessedApplicants";
import Style from "./processed.module.scss";

const ProcessedApplicants = () => {
  const {
    approvedApplicantList,
    approvedApplicantState,
    isLoading,
    isFetching,
    paginationOptions,
    filters,
    activeFilter,
    boardedModal,
    handleChangeBoarded,
    handleCloseBoardedModal,
    handleFilter,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  } = useProcessedApplicants();

  return (
    <>
      <HeadingGroup title={"Processed Applicants"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              data={filters}
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined">search</span>
              }
              showClearFilters={
                approvedApplicantState?.search === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: approvedApplicantState?.search }}
              showActions={false}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleFilter}
            />
          </div>
        </div>
        {approvedApplicantList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={approvedApplicantList?.data?.data || []}
            uniqueID={"id"}
            fields={approvedApplicantList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={approvedApplicantState?.clearSelection}
            loading={isFetching}
            perpage={approvedApplicantState?.currentPageSize}
            assignable={false}
            deletable={true}
            editable={true}
            showCheckBox={false}
          />
        )}

        {approvedApplicantList?.data?.data?.length > 0 && (
          <div>
            <Pagination
              currentPage={approvedApplicantState.currentPage}
              totalPageCount={approvedApplicantList.data.number_of_pages}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === approvedApplicantState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          </div>
        )}
      </div>

      <ModalLayout
        show={boardedModal}
        centered={"true"}
        handleClose={handleCloseBoardedModal}
        propStyle={{
          root: Style.confirmation_modal,
        }}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Are you sure to change the status of this applicant"}
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleCloseBoardedModal}
            submitText={"Yes"}
            a
            submitAction={handleChangeBoarded}
            isRight={true}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default ProcessedApplicants;
