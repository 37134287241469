import { useState } from "react";
import {
  useGetApplicantDetailTransactionQuery,
  useManuallyApprovePaymentsMutation,
} from "../../../../store/queries/Report";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useTransactionDetails = () => {
  const { applicant_id } = useParams();
  const virtualAccountNumber = sessionStorage.getItem("virtual_ac");
  const { applicantDetails } = useSelector((state) => state.applicants);
  const [manuallyApprovePayments] = useManuallyApprovePaymentsMutation();

  const applicantTransactionState = useSelector(
    (state) => state.applicantTransaction
  );

  const [approveState, setApproveState] = useState(false);
  const [rejectState, setRejectState] = useState(false);

  const { data: TransactionData = {}, isFetching } =
    useGetApplicantDetailTransactionQuery({
      search: applicantTransactionState?.search,
      fields: applicantTransactionState?.fields,
      page_number: applicantTransactionState?.currentPage,
      paginator_limit: applicantTransactionState?.currentPageSize,
      filter: {
        virtual_account_number: virtualAccountNumber,
      },
    });

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handleApproveApplicant = () => {
    setRejectState(false);
    setApproveState(true);
  };

  const handleRejectApplicant = () => {
    setApproveState(false);
    setRejectState(true);
  };

  const handleCloseApproveModal = () => {
    setApproveState(false);
    setRejectState(false);
  };

  const handleYesApprove = () => {
    const obj = {
      applicant_id: applicant_id,
      is_approved: true,
    };

    manuallyApprovePayments(obj).then((res) => {
      if (res.data.status_code === 200) {
        setApproveState(false);
        toast.success(res?.data?.message ?? "Successfully Approved");
      } else {
        toast.error(res?.data?.message ?? "Failed to Approve");
        setApproveState(false);
      }
    });
  };

  const handleYesReject = () => {
    const obj = {
      applicant_id: applicant_id,
      is_approved: false,
    };

    manuallyApprovePayments(obj).then((res) => {
      if (res.data.status_code === 200) {
        setRejectState(false);
        toast.success(res?.data?.message ?? "Successfully Approved");
      } else {
        toast.error(res?.data?.message ?? "Failed to Approve");
        setRejectState(false);
      }
    });
  };

  return {
    TransactionData,
    applicantTransactionState,
    isFetching,
    applicantDetails,
    approveState,
    rejectState,
    getRow,
    handleYesReject,
    handleYesApprove,
    handleRejectApplicant,
    handleApproveApplicant,
    handleCloseApproveModal,
  };
};

export default useTransactionDetails;
