import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Report/transactionsSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetTransactionDataQuery,
  useUpdateTransactionTableHeadDataMutation,
  useUpdateTransactionStatusMutation,
} from "../../../store/queries/Report";
import { addDays } from "date-fns";
import { getFormatedDate } from "../../../utils/Functions/table";
import { paymentsReport } from "./api";
import moment from "moment";
import { toast } from "react-toastify";

const useTransactions = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });
  const [utrValue, setUtrValue] = useState("");
  const [statusModal, setStatusModal] = useState(false);

  const transactionsState = useSelector((state) => state.transactions);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: transactionsList,
    isLoading,
    isFetching,
    refetch,
  } = useGetTransactionDataQuery({
    search: transactionsState?.search,
    fields: transactionsState?.fields,
    page_number: transactionsState?.currentPage,
    paginator_limit: transactionsState?.currentPageSize,
    date_range: {
      start_date: transactionsState.dateRange[0],
      end_date: transactionsState.dateRange[1],
    },
  });

  const [updateTransactionTableHead] =
    useUpdateTransactionTableHeadDataMutation();

  const [updateTransactionStatus] = useUpdateTransactionStatusMutation();

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };
  useEffect(() => {
    if (transactionsState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [transactionsState?.clearSelection]);
  const handleSort = (label) => {
    if (transactionsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            transactionsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      amount: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      has_refund: (field, data) => {
        const isChecked = data?.has_refund;

        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                // onChange={() => changeStatus(data?.utr)}
                onChange={() => handleChangeStatus(data?.utr)}
                disabled={data?.has_refund}
              />
              <span></span>
            </div>
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handleChangeStatus = (data) => {
    setUtrValue(data);
    setStatusModal(true);
  };

  const changeStatus = () => {
    updateTransactionStatus({ utr: utrValue }).then((res) => {
      if (res.data?.status_code === 200) {
        toast.success(res?.data?.message);
        setStatusModal(false);
      } else {
        setStatusModal(false);
        toast.error("Failed to add item in refund list");
      }
    });
  };

  const handleCloseStatusModal = () => {
    setStatusModal(false);
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    // dispatch(getStaffEditData(e?.[0]));
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    setInitialDateRange(range);

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const handleDownloadClick = () => {
    paymentsReport({
      start_date: transactionsState.dateRange[0],
      end_date: transactionsState.dateRange[1],
    }).then((res) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(res["data"]);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  return {
    isLoading,
    isFetching,
    showEditModal,
    transactionsList,
    paginationOptions,
    transactionsState,
    initialDateRange,
    statusModal,
    changeStatus,
    handleCloseStatusModal,
    handleDateChange,
    refetch,
    handleDownloadClick,
    updateTransactionTableHead,
    handleCreateClick,
    handleDeleteModal,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeEditModal,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
  };
};

export default useTransactions;
