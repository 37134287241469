import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useBasicDetailsForm from "./useBasicDetailsForm";
import { capitalizeOnSpace } from "../../../../../utils/Functions/table";

const BasicDetailsForm = ({ closeDetailsEditModal, profileData, refetch }) => {
  const { formik, getFieldError } = useBasicDetailsForm({
    closeDetailsEditModal,
    profileData,
    refetch,
  });
  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <Input
            label={"First Name"}
            type="text"
            id="firstname"
            name="firstname"
            className={`pro-input lg ${getFieldError("firstname") && " error"}`}
            {...formik.getFieldProps("firstname")}
            onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={getFieldError("firstname")}
            errorMessage={getFieldError("firstname")}
          />

          <Input
            label={"Last Name"}
            type="text"
            id="lastname"
            name="lastname"
            className={`pro-input lg ${getFieldError("lastname") && " error"}`}
            {...formik.getFieldProps("lastname")}
            onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={getFieldError("lastname")}
            errorMessage={getFieldError("lastname")}
          />

          <Input
            label={"Email"}
            type="text"
            id="email"
            name="email"
            className={`pro-input lg ${getFieldError("email") && " error"}`}
            {...formik.getFieldProps("email")}
            error={getFieldError("email")}
            errorMessage={getFieldError("email")}
          />

          <Input
            label={"Phone Number"}
            type="text"
            id="phone_number"
            name="phone_number"
            className={`pro-input lg ${
              getFieldError("phone_number") && " error"
            }`}
            {...formik.getFieldProps("phone_number")}
            onChange={(event) =>
              formik.setFieldValue("phone_number", event.target.value)
            }
            error={getFieldError("phone_number")}
            errorMessage={getFieldError("phone_number")}
          />

          <Input
            label={"Address 1"}
            type="text"
            id="address_1"
            name="address_1"
            className={`pro-input lg ${getFieldError("address_1") && " error"}`}
            {...formik.getFieldProps("address_1")}
            onChange={(event) =>
              formik.setFieldValue("address_1", event.target.value)
            }
            error={getFieldError("address_1")}
            errorMessage={getFieldError("address_1")}
          />
          <Input
            label={"Address 2"}
            type="text"
            id="address_2"
            name="address_2"
            className={`pro-input lg ${getFieldError("address_2") && " error"}`}
            {...formik.getFieldProps("address_2")}
            onChange={(event) =>
              formik.setFieldValue("address_2", event.target.value)
            }
            error={getFieldError("address_2")}
            errorMessage={getFieldError("address_2")}
          />

          <Input
            label={"State"}
            type="text"
            id="state"
            name="state"
            className={`pro-input lg ${getFieldError("state") && " error"}`}
            {...formik.getFieldProps("state")}
            onChange={(event) =>
              formik.setFieldValue("state", event.target.value)
            }
            error={getFieldError("state")}
            errorMessage={getFieldError("state")}
          />

          <Input
            label={"Zipcode"}
            type="text"
            id="zipcode"
            name="zipcode"
            className={`pro-input lg ${getFieldError("zipcode") && " error"}`}
            {...formik.getFieldProps("zipcode")}
            onChange={(event) =>
              formik.setFieldValue("zipcode", event.target.value)
            }
            error={getFieldError("zipcode")}
            errorMessage={getFieldError("zipcode")}
          />
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btns"}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeDetailsEditModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            // disabled={hasUpdatePermission === true ? false : true}
            onClick={formik.handleSubmit}
          >
            {"Update"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BasicDetailsForm;
