import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  staffData: [],
  imagePreview: "",
  imageName: "",
  editStaffData: {},
  dateRange: [
    moment(Date.now()).add(-30, "days").format("YYYY-MM-DD"),
    moment(Date.now()).format("YYYY-MM-DD"),
  ],
};

const pendingApplicantSlice = createSlice({
  name: "pendingApplicants",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = pendingApplicantSlice.actions;
export default pendingApplicantSlice.reducer;
