import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Report/bookingReportSlice";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { uploadFile } from "../api";
import { toast } from "react-toastify";

const useAddReport = ({ handleCreateClick, refetch }) => {
  const dispatch = useDispatch();
  const fileStatus = [
    {
      name: "Inprogress",
      value: "Inprogress",
    },
    { name: "Completed", value: "completed" },
  ];
  const { imageName, isEdit } = useSelector((state) => state.bookingReport);
  const validation = Yup.object({
    file: Yup.string().required(`Upload file`),
    // file_status: Yup.string().required(`Select status`),
    // date: Yup.string().required(`Choose date`),
  });
  const formik = useFormik({
    initialValues: {
      file: "",
      // file_status: "",
      // date: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("upload_file", values?.file);

      uploadFile(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.imagePreview = "";
              state.imageName = "";
              state.showCreateModal = false;
            })
          );
          refetch();
          toast.success(
            response?.data?.msg ?? "Data succesfully uploaded and stored"
          );
        } else {
          dispatch(updateConfig((state) => (state.showCreateModal = false)));
          toast.error("Failed to upload file");
        }
      });
      // handleCreateClick();
    },
  });

  const handleImageChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("upload_file", selectedLogo);
      // setLastUploadedLogo(selectedLogo);
      formik.setFieldValue("file", selectedLogo);
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    } else {
      return "";
    }
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  const statusObj = fileStatus?.filter(
    (item) => item?.value === formik?.values?.file_status
  );

  return {
    formik,
    isEdit,
    fileStatus,
    imageName,
    statusObj,
    getFieldError,
    closeModal,
    handleImageChange,
  };
};

export default useAddReport;
