import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/protectRoute";

const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate(`/login`);
    } else {
      navigate(`/dashboard`);
    }
    //eslint-disable-next-line
  }, []);
  return (
    <ProtectRoute>
      <Outlet />;
    </ProtectRoute>
  );
};

export default Index;
