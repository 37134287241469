import { Navigate } from "react-router-dom";
// import useProtectRoute from "./useProtectRoute";
// import { useSelector } from "react-redux";

const ProtectRoute = ({ children }) => {
  // const { state } = useProtectRoute();
  // const globalState = useSelector((state) => state.global);
  let auth = localStorage.getItem("USER_ACCESS_TOKEN");

  if (!auth) {
    return <Navigate to={"/login"} />;
  }
  return children;
};

export default ProtectRoute;
