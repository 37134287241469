import { useDispatch } from "react-redux";
import { useGetUserDetailsDataQuery } from "../../../store/queries/User";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useFormik } from "formik";
import { passwordChange } from "./api";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useProfileView = ({ handleLogout }) => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS"));
  const { data: userData, refetch } = useGetUserDetailsDataQuery();
  const [showCurrentPassword, setShowCurrentPassword] = useState(true);
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(true);
  const profileFileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(
    userData?.data !== "" ? userData?.data?.image_url : ""
  );
  const dispatch = useDispatch();

  var validationSchemaPasswordChange = Yup.object({
    current_password: Yup.mixed().required("current password required"),
    password: Yup.mixed().required("New password is required"),
    confirm_password: Yup.mixed().required("confirm password is required"),
  });

  const formikEditPassword = useFormik({
    initialValues: {
      enableReinitialize: true,
      current_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchemaPasswordChange,
    onSubmit: (values) => {
      passwordChange(values).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Password Changed Succesfully ");
          refetch();
          handleClose();
          handleLogout();
          navigate("/");
        } else {
          toast.error(`Something went wrong!`);
        }
      });
    },
  });

  const handleShowCurrentPassword = (e) => {
    e.preventDefault();

    setShowCurrentPassword(!showCurrentPassword);
    var x = document.getElementById("current_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault();

    setNewShowPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmNewPassword = (e) => {
    e.preventDefault();

    setShowConfirmNewPassword(!showConfirmNewPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleClose = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.isProfileViewVisible = !state.isProfileViewVisible;
      })
    );
  };

  return {
    userData,
    userDetails,
    imagePreview,
    showNewPassword,
    showCurrentPassword,
    showConfirmNewPassword,
    formikEditPassword,
    profileFileInputRef,
    handleShowCurrentPassword,
    handleShowConfirmNewPassword,
    handleShowNewPassword,
    setImagePreview,
    handleClose,
  };
};

export default useProfileView;
