import React from "react";
import AdminProfileCard from "../AdminProfileCard";
import useProfileView from "./useProfileView";
import EditProfile from "./EditProfile";
import Style from "./editProfile.module.scss";

const ProfileDetails = ({ handleLogout }) => {
  const {
    userData,
    userDetails,
    countryObj,
    imagePreview,
    showCurrentPassword,
    showNewPassword,
    showConfirmNewPassword,
    countryCodeOptions,
    formikEditPassword,
    profileFileInputRef,
    handleImageChange,
    setImagePreview,
    handleClose,
    handleShowCurrentPassword,
    handleShowNewPassword,
    handleShowConfirmNewPassword,
  } = useProfileView({ handleLogout });
  return (
    <>
      <div className={Style.root}>
        <div className={Style.item}>
          <AdminProfileCard
            profileFileInputRef={profileFileInputRef}
            userData={userDetails}
            imagePreview={imagePreview}
            handleImageChange={handleImageChange}
          />
        </div>
        <EditProfile
          handleClose={handleClose}
          setImagePreview={setImagePreview}
          userData={userData?.data}
          formikEditPassword={formikEditPassword}
          countryCodeOptions={countryCodeOptions}
          countryObj={countryObj}
          showNewPassword={showNewPassword}
          handleShowNewPassword={handleShowNewPassword}
          showCurrentPassword={showCurrentPassword}
          handleShowCurrentPassword={handleShowCurrentPassword}
          showConfirmNewPassword={showConfirmNewPassword}
          handleShowConfirmNewPassword={handleShowConfirmNewPassword}
        />
      </div>
    </>
  );
};

export default ProfileDetails;
