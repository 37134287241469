import { useGetDashboardTableDataQuery } from "../../../../store/queries/Dashboard";
import { getFormatedDate } from "../../../../utils/Functions/table";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Dashboard/dashboardSlice";
import { useEffect } from "react";

const useTransactionFilters = ({ filter }) => {
  const dispatch = useDispatch();
  const dashbaordListingState = useSelector((state) => state.dashboardListing);
  const { data: dashboardTableData, isFetching } =
    useGetDashboardTableDataQuery({
      filter: filter,
      page_number: dashbaordListingState?.currentPage,
      paginator_limit: dashbaordListingState?.currentPageSize,
    });

  //making pagination 1 and page size 10 while switching tabs
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
        state.currentPage = 1;
      })
    );
    //eslint-disable-next-line
  }, [filter]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      booking_date: (field, data) => (
        <p className="pro-mb-0">{getFormatedDate(data[field])}</p>
      ),
      amount_from: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      amount_to: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      tax_amount: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    dashboardTableData,
    isFetching,
    dashbaordListingState,
    paginationOptions,
    handlePageSize,
    handlePagination,
    getRow,
  };
};

export default useTransactionFilters;
