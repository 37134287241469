import style from "./dashboardCard.module.scss";
const DashboardCard = ({ title, data, icon, active, transactions }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner}`}>
        <div className={`${style.body}`}>
          <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
            {data !== null && data !== undefined ? data : null}
          </h3>
          <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
            {data?.month ? `${title}(${data?.month}) ` : title}
          </h6>
          {/* <div
            className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
          >
            {data?.comparison_status && (
              <span
                className={`${
                  data?.comparison_status === "greater"
                    ? style.increment
                    : data?.comparison_status === "lesser"
                    ? style.decrement
                    : ""
                } ${
                  style.growth_percentage
                } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
              >
                <span className={`material-symbols-outlined ${style.arrow}`}>
                  arrow_outward
                </span>
                <span>{data?.percentage_difference}%</span>
              </span>
            )}

            {data?.comparison_status && (
              <p
                className={`pro-mb-0 pro-font-xs pro-fw-medium ${style.last_month}`}
              >
                {title === "Total Orders" ? "Last Year" : "Last Month"}
              </p>
            )}
          </div> */}
        </div>

        <div className={`${style.icon}`}>
          <span className="material-symbols-outlined x4">{icon}</span>
        </div>
      </div>
    </div>
  );
};
export default DashboardCard;
