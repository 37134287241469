import {
  HeadingGroup,
  Table,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../../Global/EmptyData";
import useTransactionFilters from "./useTransactionFilters";
import { FaSort } from "react-icons/fa";

const TransactionFilters = ({ filter, title }) => {
  const {
    dashboardTableData,
    isFetching,
    dashbaordListingState,
    paginationOptions,
    handlePageSize,
    handlePagination,
    getRow,
  } = useTransactionFilters({
    filter,
  });
  return (
    <>
      <HeadingGroup title={title} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row"></div>
        {dashboardTableData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={dashboardTableData?.data?.data || []}
            uniqueID={"payment_id"}
            fields={dashboardTableData?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            // handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={dashbaordListingState?.currentPageSize}
            showCheckBox={false}
          />
        )}

        {dashboardTableData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={dashbaordListingState?.currentPage}
            totalPageCount={dashboardTableData?.data?.number_of_pages}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === dashbaordListingState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
    </>
  );
};

export default TransactionFilters;
