import React from "react";
import Style from "../editProfile.module.scss";

// import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const EditProfile = ({
  handleClose,
  formikEditPassword,
  showCurrentPassword,
  handleShowCurrentPassword,
  showNewPassword,
  handleShowNewPassword,
  showConfirmNewPassword,
  handleShowConfirmNewPassword,
}) => {
  return (
    <>
      <div className={Style.item}>
        <h5>Change Password</h5>
        <div className="input-wrap p-re pro-mb-4">
          <label
            htmlFor="current_password"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Current Password
          </label>
          <input
            type="password"
            className={`pro-input lg ${
              formikEditPassword.errors.current_password &&
              formikEditPassword.touched.current_password &&
              "error"
            }`}
            id="current_password"
            name="current_password"
            {...formikEditPassword.getFieldProps("current_password")}
          />
          {showCurrentPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowCurrentPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowCurrentPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
          {formikEditPassword.touched.current_password &&
            formikEditPassword.errors.current_password && (
              <span className="error-text">
                {formikEditPassword.errors.current_password}
              </span>
            )}
        </div>

        <div className="input-wrap p-re pro-mb-4">
          <label
            htmlFor="password"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            New password
          </label>
          <input
            type="password"
            className={`pro-input lg ${
              formikEditPassword.errors.password &&
              formikEditPassword.touched.password &&
              "error"
            }`}
            id="password"
            name="password"
            {...formikEditPassword.getFieldProps("password")}
          />
          {showNewPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowNewPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowNewPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
          {formikEditPassword.touched.password &&
            formikEditPassword.errors.password && (
              <span className="error-text">
                {formikEditPassword.errors.password}
              </span>
            )}
        </div>

        <div className="input-wrap p-re">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Confirm password
          </label>
          <input
            type="password"
            className={`pro-input lg ${
              formikEditPassword.errors.confirm_password &&
              formikEditPassword.touched.confirm_password &&
              "error"
            }`}
            id="confirm_password"
            name="confirm_password"
            {...formikEditPassword.getFieldProps("confirm_password")}
          />

          {showConfirmNewPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmNewPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmNewPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
          {formikEditPassword.touched.confirm_password &&
            formikEditPassword.errors.confirm_password && (
              <span className="error-text">
                {formikEditPassword.errors.confirm_password}
              </span>
            )}
        </div>

        <div className="row">
          <div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              onClick={formikEditPassword.handleSubmit}
              // type="submit"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
