import { getAdminAxiosInstance } from "../../../../api";

export const getImage = async (url) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`${url}`, { responseType: 'blob' });
    return response;
  } catch (error) {
    return error; 
  }
};

export const verifyDocument = async (data) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`document-aprrove-or-reject`, data);

    return response;
  } catch (error) {
    return error; 
  }
};

