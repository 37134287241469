import React from "react";
import useActivityDetails from "./useActivityDetails";
import Style from "./activity.module.scss";

const ActivityDetails = () => {
  const { ActivityData, isFetching } = useActivityDetails();
  return (
    <div className="pro-py-6">
      <p pro-fw-medium pro-font-sm pro-mb-0>
        <span className="pro-me-3 material-symbols-outlined x3">
          in_home_mode
        </span>
        Collect at LuLu Forex
      </p>
      <div>
        {isFetching ? (
          <p>Loading</p>
        ) : (
          <div className={Style.followUp_list_wrap}>
            {ActivityData?.data?.map((value, index) => {
              return (
                <div className={Style.item} key={index}>
                  <div className={`${Style.list_item}`}>
                    {value?.data &&
                      value?.data?.map((item) => (
                        <>
                          <p className={`${Style.description}`}>{item.title}</p>
                          {item.user && (
                            <p className="pro-fw-medium pro-font-sm pro-mb-0 pro-d-flex pro-ms-2">
                              <span className=" material-symbols-outlined x2">
                                person
                              </span>
                              {item?.user["first_name"] +
                                item?.user["lst_name"]}
                            </p>
                          )}
                        </>
                      ))}
                    <h6 className={`pro-mb-0 h6 pro-ttl ${Style.title}`}>
                      {value?.title}
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;
