import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Report/Applicant/applicantsSlice";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  useGetApplicantDataQuery,
  useUpdateApplicantTableHeadDataMutation,
} from "../../../store/queries/Report";
import { useNavigate } from "react-router-dom";

const useApplicants = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const applicantsState = useSelector((state) => state.applicants);

  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: applicantsList,
    isLoading,
    isFetching,
    refetch,
  } = useGetApplicantDataQuery({
    search: applicantsState?.search,
    fields: applicantsState?.fields,
    page_number: applicantsState?.currentPage,
    paginator_limit: applicantsState?.currentPageSize,
    order_by: {
      field: applicantsState?.sortBy || "",
      order: applicantsState?.sortOrder || "",
    },
    filter: {
      status: applicantsState?.currentFilter,
      currency_to: applicantsState.currencyCode,
    },
  });

  const [updateApplicantsTableHead] = useUpdateApplicantTableHeadDataMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };
  useEffect(() => {
    if (applicantsState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [applicantsState?.clearSelection]);

  const handleSort = (label) => {
    if (applicantsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            applicantsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      amount_from: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      amount_to: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      tax_amount: (field, data) => (
        <p className="pro-mb-0">{parseFloat(data[field]).toLocaleString()}</p>
      ),
      virtural_account_number: (field, data) => (
        <p
          className={`pro-mb-0 pro-pnt`}
          onClick={() => {
            navigate({
              pathname: `activity-details/${data?.id}`,
            });

            dispatch(
              updateConfig((state) => {
                state.currentRatePageSize = 10;
                state.currentRatePage = 1;
              })
            );
            sessionStorage.setItem("active", `${data?.id}`);
            sessionStorage.setItem(
              "virtual_ac",
              `${data?.virtural_account_number}`
            );
            sessionStorage.setItem("data_status", `${data?.status}`);
          }}
        >
          {data?.[field]}
        </p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    // dispatch(getStaffEditData(e?.[0]));
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const filters = useMemo(() => {
    if (applicantsList?.data?.filter_data) {
      const buttonGroups = applicantsList?.data?.filter_data?.filter(
        (d) => !d?.type
      );
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, applicantsList]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleChangeCurrencyCode = (value) => {
    if (value) {
      dispatch(
        updateConfig((state) => {
          state.currencyCode = value.code;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currencyCode = "";
        })
      );
    }
  };

  return {
    isLoading,
    isFetching,
    showEditModal,
    applicantsList,
    paginationOptions,
    applicantsState,
    filters,
    activeFilter: applicantsState.currentFilter,
    handleChangeCurrencyCode,
    handleFilter,
    updateApplicantsTableHead,
    handleCreateClick,
    handleDeleteModal,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeEditModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useApplicants;
