import React from "react";
import ProtectRoute from "../../../utils/components/ProtectRoute/protectRoute";
import { Header, MenuDrawer } from "@wac-ui-dashboard/wac_component_library";
import { Link } from "react-router-dom";
import Assets from "../../../assets/Assets";

import Style from "./commonLayout.module.scss";
import useCommonLayout from "./useCommonLayout";
import Nav from "react-bootstrap/Nav";
import HeaderActions from "./HeaderActions";

const CommonLayout = ({ children, drawerMenu, isDashboard, isGrey }) => {
  const { navigations, menuDrawer, globalState, handleGoBack } =
    useCommonLayout();

  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.LOGO
            : Assets.LOGODARK
        }
        children={<HeaderActions />}
        propStyle={{
          header: Style.header,
          headerInner: Style.headerInner,
        }}
      />
      <div className={`pro-w-100 container-fluid pt-custom`}>
        <div className={`row`}>
          {!isDashboard ? (
            <div
              className={`col-auto wrapper pro-ps-0 ${Style.MenuDrawer_container}`}
            >
              <MenuDrawer
                menus={drawerMenu}
                Link={Link}
                type={globalState?.drawerTheme === "expanded" ? "type-2" : ""}
                Nav={Nav}
                icon={
                  <span className="material-symbols-outlined ">
                    chevron_right
                  </span>
                }
                isGoBack={menuDrawer?.show}
                goBackTitle={menuDrawer?.text}
                goBackIcon={
                  <span className="material-symbols-outlined">
                    chevron_left
                  </span>
                }
                handleGoBack={handleGoBack}
              />
            </div>
          ) : (
            <></>
          )}

          <div
            className={`wrapper-table pro-pt-4 pro-pe-5 pro-ps-2 ${
              isGrey ? `grey_bg` : ""
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default CommonLayout;
