import React from "react";
import Dashboard from "../components/Dashboard";
import Report from "../components/Report";
import User from "../components/User";
import Settings from "../components/Settings";

const useRoutes = () => {
  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];
  const ReportComponent = [
    {
      component: <Report />,
      permission: "Report",
      path: "report",
    },
  ];
  const UserComponent = [
    {
      component: <User />,
      permission: "User",
      path: "user",
    },
  ];
  const SettingsComponent = [
    {
      component: <Settings />,
      permission: "Settings",
      path: "settings",
    },
  ];
  const drawerMenuPermission = ["Dashboard", "Report", "User", "Settings"];
  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent?.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedReportComponent = ReportComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedUserComponent = UserComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedSettingsComponent = SettingsComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  return {
    allowedDashboardComponent,
    allowedReportComponent,
    allowedUserComponent,
    allowedSettingsComponent,
  };
};

export default useRoutes;
