import { Button, IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddReport from "./useAddReport";

const AddReport = ({ isStickyFooter, handleCreateClick, refetch }) => {
  const { formik, isEdit, imageName, closeModal, handleImageChange } =
    useAddReport({ handleCreateClick, refetch });
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            File
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              className={`pro-input ${
                formik.errors.file && formik.touched.file && " error"
              }`}
              id="file"
              onBlur={formik.handleBlur("file")}
              onChange={(e) => handleImageChange(e)}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  imageName !== ""
                    ? imageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.file && formik.errors.file && (
              <span className="error-text">{formik.errors.file}</span>
            )}
          </div>
        </div>
        {/* <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            File Status
          </label>
          <Select
            id="file_status"
            name="file_status"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("file_status") && " error"
            }`}
            classNamePrefix="pro-input"
            options={fileStatus}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.value}
            value={statusObj}
            onBlur={formik.handleBlur("file_status")}
            onChange={(value) =>
              formik?.setFieldValue("file_status", value?.value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("file_status") && (
            <span className="error-text">{getFieldError("file_status")}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Date
          </label>
          <input
            className={`pro-input lg`}
            type="date"
            name="date"
            placeholder="Select Date"
            onChange={formik.handleChange}
            value={formik.values.date}
          />
        </div> */}
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            // disabled={hasUpdatePermission === true ? false : true}
            onClick={formik.handleSubmit}
          >
            {!isEdit ? "Upload" : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddReport;
