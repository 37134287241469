import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import AddUser from "./AddUser";
import useUserList from "./useUserList";

const UserList = () => {
  const {
    isLoading,
    isFetching,
    userList,
    paginationOptions,
    userState,
    handleCreateClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleDeleteModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useUserList();
  return (
    <>
      <HeadingGroup
        title={"Users"}
        className={`pro-mb-4`}
        buttonTitle={"Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={userState?.search === "" ? false : true}
              handleClear={handleClearClick}
              searchInputProps={{ value: userState?.search }}
              showActions={false}
            />
          </div>
        </div>
        {userList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={userList?.data?.data || []}
              uniqueID={"id"}
              fields={userList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              clear={userState?.clearSelection}
              loading={isFetching}
              perpage={userState?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDeleteModal}
              deletable={true}
              editable={true}
              showCheckBox={true}
            />
            {userList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={userState?.currentPage}
                totalPageCount={Math.ceil(
                  userList?.data?.total_count / userState.currentPageSize
                )}
                onPageChange={handlePagination}
                defaultValue={paginationOptions?.filter(
                  (item) => item.value === userState.currentPageSize
                )}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={userState?.showCreateModal}
          handleClose={handleCreateClick}
          title={userState?.isEdit ? "Update User" : "Add User"}
          backdrop={"static"}
        >
          <AddUser refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default UserList;
