import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useValidations from "../../../../../utils/hooks/useValidations";
import { useUpdateApplicantDetailsMutation } from "../../../../../store/queries/Report";

const useBasicDetailsForm = ({
  closeDetailsEditModal,
  profileData,
  refetch,
}) => {
  const [updateApplicantDetails] = useUpdateApplicantDetailsMutation();
  const { emailRegExp, englishOnlyRegex } = useValidations();

  const validation = Yup.object({
    firstname: Yup.string()
      .matches(englishOnlyRegex, `Enter valid name`)
      .required(`First Name is Required`),
    lastname: Yup.string()
      .matches(englishOnlyRegex, `Enter valid name`)
      .required(`Last Name is Required`),
    email: Yup.string()
      .matches(emailRegExp, `Enter valid email address`)
      .required(`Email is Required`),
    phone_number: Yup.number().required("Phone Number is Required"),
    address_1: Yup.string().required("Address 1 is Required"),
    address_2: Yup.string().required("Address 2 is Required"),
    state: Yup.string().required("State is Required"),
    zipcode: Yup.number().required("Zipcode is Required"),
  });

  const formik = useFormik({
    initialValues: {
      firstname: profileData?.result?.first_name ?? "",
      lastname: profileData?.result?.last_name ?? "",
      email: profileData?.result?.sender_email ?? "",
      phone_number: profileData?.result?.sender_phone ?? "",
      address_1: profileData?.result?.sender_address1 ?? "",
      address_2: profileData?.result?.sender_address2 ?? "",
      state: profileData?.result?.sender_state ?? "",
      zipcode: profileData?.result?.sender_zip ?? "",
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      let data = {
        applicant_id: profileData?.result?.applicant_id,
        first_name: values?.firstname,
        last_name: values?.lastname,
        sender_email: values?.email,
        sender_phone: values?.phone_number,
        sender_address1: values?.address_1,
        sender_address2: values?.address_2,
        sender_state: values?.state,
        sender_zip: values?.zipcode,
      };

      updateApplicantDetails(data).then((res) => {
        if (res?.data?.status_code === 200) {
          closeDetailsEditModal();
          refetch();
          toast.success("User details updated successfully");
        } else {
          toast.error("Failed to update user details");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    } else {
      return "";
    }
  };

  return { formik, getFieldError };
};

export default useBasicDetailsForm;
