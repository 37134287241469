import DashboardCardShimmer from "../../Global/Shimmers/DashboardCardShimmer";
import DashboardCard from "../DashboardCard";
import Style from "./dashboardHero.module.scss";

const DashboardHero = ({
  data,
  obj,
  // transactions = false,
  loading,
  listingType,
  shimmerCount = 3,
}) => {

  return (
    <div className={`pro-w-100`}>
      <div className={`row g-4`}>
        {!loading ? (
          <>
            {Object.keys(data || {})?.map((key, index) => {
              return (
                <div
                  key={index}
                  className={`col-3 ${Style.dashboardCard_root}`}
                  onClick={() => obj?.[key]?.handleClick(obj?.[key]?.label)}
                >
                  <DashboardCard
                    icon={obj?.[key]?.icon}
                    title={obj?.[key]?.title}
                    data={data?.[key]}
                    active={obj?.[key]?.label === listingType ? true : false}
                    // transactions={transactions}
                  />
                </div>
              );
            })}
          </>
        ) : (
          [...Array(shimmerCount)].map((_, i) => (
            <div className="col-3" key={i}>
              <DashboardCardShimmer key={i} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
