import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useApplicants from "./useApplicants";
import Select from "react-select";
import currencyData from "../../../utils/Functions/currencyCode";
import Style from "./applicants.module.scss";

const Applicants = () => {
  const {
    isLoading,
    isFetching,
    applicantsList,
    paginationOptions,
    applicantsState,
    activeFilter,
    filters,
    handleChangeCurrencyCode,
    handleFilter,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleDeleteModal,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
  } = useApplicants();
  return (
    <>
      <HeadingGroup title={"Applicants"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              data={filters}
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={applicantsState?.search === "" ? false : true}
              handleClear={handleClearClick}
              searchInputProps={{ value: applicantsState?.search }}
              showActions={false}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleFilter}
              extraFilters={
                <div className={`col-1 pro-ms-auto ${Style.col}`}>
                  <div className="input-wrap">
                    <Select
                      id="currency_code"
                      placeholder={"Select"}
                      className={`pro-input lg`}
                      classNamePrefix="pro-input"
                      name="currency_code"
                      options={currencyData}
                      getOptionValue={(option) => option?.code}
                      getOptionLabel={(option) => option?.code}
                      value={currencyData?.filter(
                        (item) => item.code === applicantsState.currencyCode
                      )}
                      onChange={(value) => handleChangeCurrencyCode(value)}
                      menuPlacement="auto"
                      isClearable
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>

        {applicantsList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={applicantsList?.data?.data || []}
            uniqueID={"id"}
            fields={applicantsList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={applicantsState?.clearSelection}
            loading={isFetching}
            perpage={applicantsState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={true}
            editable={true}
            showCheckBox={true}
          />
        )}

        {applicantsList?.data?.data?.length > 0 && (
          <div>
            <Pagination
              currentPage={applicantsState?.currentPage}
              totalPageCount={Math.ceil(
                applicantsList?.data?.total_count /
                  applicantsState.currentPageSize
              )}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === applicantsState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Applicants;
