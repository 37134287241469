import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useSettings = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "Appearance",
      link: "/settings/appearance",
      icon: <span className="material-symbols-outlined x4">settings</span>,
      active: checkIfActiveRoute("/settings/appearance", true),
    },
  ];
  //   const drawerMenuPermission = useMemo(() => {
  //     let menus = ["Booking Report", "Transaction", "Applicants"];
  //     return drawerMenu
  //       ?.filter((menu) => {
  //         return menus?.includes?.(menu?.title);
  //       })
  //       ?.map((menu) => {
  //         return {
  //           ...menu,
  //         };
  //       });
  //     //eslint-disable-next-line
  //   }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenu };
};

export default useSettings;
