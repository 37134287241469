import React from "react";
import CommonLayout from "../../pages/Layout/CommonLayout";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <CommonLayout isDashboard>
      <Outlet />
    </CommonLayout>
  );
};

export default Dashboard;
