import { getAdminAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`account/api-login`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const forgotPassword = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`account/api-email-otp`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const verifyOTP = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`account/api-verify-otp`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const changePassword = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`account/api-otp-password-reset`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
