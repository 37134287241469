import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  staffData: [],
  imagePreview: "",
  imageName: "",
  editStaffData: {},
  fields: [],
};

const dashbaordSlice = createSlice({
  name: "dashboardListing",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = dashbaordSlice.actions;
export default dashbaordSlice.reducer;
