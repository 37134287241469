import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useTransactions from "./useTransactions";
import Style from "./transactions.module.scss";

const Transactions = () => {
  const {
    isLoading,
    isFetching,
    transactionsList,
    paginationOptions,
    transactionsState,
    initialDateRange,
    statusModal,
    changeStatus,
    handleCloseStatusModal,
    handleDateChange,
    handleDownloadClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleDeleteModal,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
  } = useTransactions();

  return (
    <>
      <HeadingGroup title={"Transactions"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={transactionsState?.search === "" ? false : true}
              handleClear={handleClearClick}
              searchInputProps={{ value: transactionsState?.search }}
              showActions={false}
              //date picker props
              showDateRange={true}
              initialDateRange={initialDateRange}
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 pro-pt-3 `}
              onClick={handleDownloadClick}
            >
              <span class="material-symbols-outlined">download</span>
              <span>Download</span>
            </button>
          </div>
        </div>
        {transactionsList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={transactionsList?.data?.data || []}
            uniqueID={"payment_id"}
            fields={transactionsList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={transactionsState?.clearSelection}
            loading={isFetching}
            perpage={transactionsState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={true}
            editable={true}
            showCheckBox={false}
          />
        )}
        {transactionsList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={transactionsState?.currentPage}
            totalPageCount={Math.ceil(
              transactionsList?.data?.total_count /
                transactionsState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === transactionsState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>

      <ModalLayout
        show={statusModal}
        centered={"true"}
        handleClose={handleCloseStatusModal}
        propStyle={{
          root: Style.confirmation_modal,
        }}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Are you sure to change the status of this transaction"}
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleCloseStatusModal}
            submitText={"Yes"}
            a
            submitAction={changeStatus}
            isRight={true}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default Transactions;
