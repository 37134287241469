import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateConfig } from "../../../../store/slices/User/userSlice";
import { useSelector } from "react-redux";
import useValidations from "../../../../utils/hooks/useValidations";
import { addUser, updateUser } from "../api";
import { toast } from "react-toastify";
import { useState } from "react";

const useAddUser = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, selectedUserDetails, selectedId } = useSelector(
    (state) => state.user
  );
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [showReEnterPassword, setShowReEnterPassword] = useState(true);
  const { englishOnlyRegex } = useValidations();

  const validation = Yup.object({
    firstname: Yup.string()
      .matches(englishOnlyRegex, `Enter valid name`)
      .required(`First Name is Required`),
    lastname: Yup.string()
      .matches(englishOnlyRegex, `Enter valid name`)
      .required(`Last Name is Required`),
    email_or_emp_id: Yup.string()
      // .matches(emailRegExp, `Enter valid email address`)
      .required(`Email / Employee ID is Required`),
  });
  const formik = useFormik({
    initialValues: {
      firstname:
        selectedUserDetails !== "" ? selectedUserDetails?.first_name : "",
      lastname:
        selectedUserDetails !== "" ? selectedUserDetails?.last_name : "",
      email_or_emp_id:
        selectedUserDetails !== "" ? selectedUserDetails?.email : "",
      is_admin:
        selectedUserDetails !== ""
          ? selectedUserDetails?.user_type === "admin"
            ? true
            : false
          : false,
      is_superadmin:
        selectedUserDetails !== ""
          ? selectedUserDetails?.user_type === "super admin"
            ? true
            : false
          : false,
      user_password:
        selectedUserDetails !== "" ? selectedUserDetails?.user_password : "",
      confirm_user_password:
        selectedUserDetails !== ""
          ? selectedUserDetails?.user_confirm_password
          : "",
    },

    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      if (!values.user_password && !isEdit) {
        errors.user_password = "*Password Required";
      }
      if (!values.confirm_user_password && !isEdit) {
        errors.confirm_user_password = "*Confirm Password Required";
      }
      if (values.confirm_user_password !== values.user_password && isEdit) {
        errors.confirm_user_password =
          "Password doesn't match with new password";
      }
      return errors;
    },
    onSubmit: async (values) => {
      let data = {
        firstname: values?.firstname,
        lastname: values?.lastname,
        email_or_emp_id: values?.email_or_emp_id,
        password: values?.user_password,
        confirm_password: values?.confirm_user_password,
        is_admin: values?.is_admin,
        is_super_admin: values?.is_superadmin,
      };

      let updateData = {
        first_name: values?.firstname,
        last_name: values?.lastname,
        email_or_emp_id: values?.email_or_emp_id,
        password: null,
        confirm_password: null,
        is_admin: values?.is_admin,
        is_super_admin: values?.is_superadmin,
        id: selectedId ? selectedId : "",
      };

      if (!isEdit) {
        addUser(data).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(response?.data?.message);
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            refetch();
          } else if (response?.status_code === 400) {
            toast.error(response?.error);
          } else {
            toast.error("failed To Add User");
          }
        });
      } else {
        updateUser(updateData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(response?.data?.message);
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
                state.selectedId = "";
                state.isEdit = false;
                state.selectedUserDetails = {};
                state.clearSelectedUser = true;
              })
            );
            refetch();
          } else if (response?.status_code === 400) {
            toast.error(response?.error);
          } else {
            toast.error("Failed To Update User");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    } else {
      return "";
    }
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault();

    setNewShowPassword(!showNewPassword);
    var x = document.getElementById("user_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowReEnteredPassword = (e) => {
    e.preventDefault();

    setShowReEnterPassword(!showReEnterPassword);
    var x = document.getElementById("confirm_user_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    isEdit,
    showNewPassword,
    showReEnterPassword,
    handleShowNewPassword,
    handleShowReEnteredPassword,
    closeModal,
    getFieldError,
  };
};

export default useAddUser;
