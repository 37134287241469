import { useSelector, useDispatch } from "react-redux";
import { useGetPendingApplicantQuery } from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/pendingApplicantSlice";

const usePendingApplicants = () => {
  const dispatch = useDispatch();
  const pendingApplicantState = useSelector((state) => state.pendingApplicants);
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: pendingApplicantList,
    isLoading,
    isFetching,
  } = useGetPendingApplicantQuery({
    search: pendingApplicantState?.search,
    //     fields: pendingApplicantState?.fields,
    page_number: pendingApplicantState?.currentPage,
    paginator_limit: pendingApplicantState?.currentPageSize,
    order_by: {
      field: pendingApplicantState?.sortBy || "",
      order: pendingApplicantState?.sortOrder || "",
    },
    //     filter: {
    //       status: pendingApplicantState?.currentFilter,
    //     },
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };

  const handleSort = (label) => {
    if (pendingApplicantState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            pendingApplicantState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    pendingApplicantList,
    pendingApplicantState,
    isLoading,
    paginationOptions,
    isFetching,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  };
};

export default usePendingApplicants;
