import axios from "axios";

let BASE_URL;
switch (process.env.REACT_APP_ENV) {
  case "PRODUCTION":
    BASE_URL = process.env.REACT_APP_ADMIN_PRODURL;
    break;
  case "QA":
    BASE_URL = process.env.REACT_APP_ADMIN_QA_URL;
    break;
  case "WEBC":
    BASE_URL = process.env.REACT_APP_ADMIN_APIURL;
    break;
  case "LOCAL":
    BASE_URL = process.env.REACT_APP_LOCAL;
    break;
  default:
    break;
}

export const getAdminAxiosInstance = () => {
  // Create an Axios instance
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  // Use a request interceptor to dynamically add the Authorization header to each request
  instance.interceptors.request.use(
    (config) => {
      // Obtain tokens from localStorage
      const token = localStorage.getItem("USER_ACCESS_TOKEN");
      const resetToken = localStorage.getItem("PASSWORD_CHANGE_TOKEN");

      // Determine which token to use (prefer resetToken if available)
      const authToken = resetToken
        ? `Bearer ${resetToken}`
        : token
        ? `Bearer ${token}`
        : null;

      // Set the Authorization header if a token is available
      if (authToken) {
        config.headers["Authorization"] = authToken;
      }

      // Set default headers here if any
      config.headers["Accept"] = "application/json";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //checking whether the token is expired or not ,if expired it will navigates to login
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("USER_ACCESS_TOKEN");
          localStorage.removeItem("is_admin");
          localStorage.removeItem("USER_DETAILS");
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/login";
        }
      }
      return Promise.reject(error);
    }
  );

  // No need to use try-catch or async-await here, as creating an instance and setting up interceptors doesn't involve asynchronous operations
  return instance;
};
