import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  showEditModal: false,
  showDeleteModal: false,
  isProfileViewVisible: false,
  menuDrawer: {},
  isLogged: false,
  otpReceived: false,
  currentPage: 1,
  otpGenerated: "",
  tableFields: {},
  searchData: {},
  searchStatus: "idle",
  searchKey: "",
  drawerTheme: "expanded",
  currentTheme: "light",
  isNewNotification: false,
  selectedId: "",
  imagePreview: "",
  access: "",
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = globalSlice.actions;
export default globalSlice.reducer;
