import { getAdminAxiosInstance } from "../../../api";

export const passwordChange = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`account/api-reset-password`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
