import { getAdminAxiosInstance } from "../../../api";

export const uploadFile = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`file-upload`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
