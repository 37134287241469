import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort")}sort_order=${
            params?.sort_order || "desc"
          }&paginator_limit=${params?.page_size || "10"}&${getParams(
            "search"
          )}page=${params?.page || 1}&${getParams("staff_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const user = createApi({
  reducerPath: "userApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["User", "Profile"],
  endpoints: (builder) => ({
    //user section starts
    getUserListData: builder.query({
      query: (values) => ({
        method: "post",
        values,
        endpoint: "account/api-user-listing",
      }),
      providesTags: ["User"],
    }),
    updateUserListTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `account/api-user-listing`,
      }),
      invalidatesTags: ["User"],
    }),
    //user section ends
    addUser: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "account/api-create-user",
      }),
      invalidatesTags: ["User"],
    }),
    getUserDetailsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/admin/profile",
      }),
      providesTags: ["Profile"],
    }),
  }),
});

export const {
  useGetUserListDataQuery,
  useUpdateUserListTableHeadDataMutation,
  useAddUserMutation,
  useGetUserDetailsDataQuery,
} = user;
