import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import TransactionFilters from "../DashboardListing/TransactionFilters/index";

const useDashboardListing = () => {
  // const dispatch = useDispatch();
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  const handleTotalTransactions = () => {
    setListingType("total_transaction");
  };
  const handlePendingTransactions = () => {
    setListingType("pending_transaction");
  };
  const handleSuccessFullTransaction = () => {
    setListingType("successful_transaction");
  };
  const handleVerifyDocuments = () => {
    setListingType("verify_documents");
  };

  let object = {
    total_transaction: handleTotalTransactions,
    pending_transaction: handlePendingTransactions,
    successful_transaction: handleSuccessFullTransaction,
    verify_documents: handleVerifyDocuments,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    total_transaction: {
      label: "total_transaction",
      title: "Total Transactions",
      icon: "playlist_add_check",
      handleClick,
      component: (
        <TransactionFilters filter={""} title={"Total Transactions"} />
      ),
    },
    pending_transaction: {
      label: "pending_transaction",
      title: "Pending Transactions",
      icon: "list_alt_add",
      handleClick,
      component: (
        <TransactionFilters
          filter={"pending_transaction"}
          title={"Pending Transactions"}
        />
      ),
    },
    successful_transaction: {
      label: "successful_transaction",
      title: "Successful Transactions",
      icon: "paid",
      handleClick,
      component: (
        <TransactionFilters
          filter={"successful_transaction"}
          title={"Successful Transactions"}
        />
      ),
    },
    verify_documents: {
      label: "verify_documents",
      title: "Verify Documents",
      icon: "task",
      handleClick,
      component: (
        <TransactionFilters
          filter={"verify_documents"}
          title={"Verify Documents"}
        />
      ),
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
    isSuccess,
  };
};

export default useDashboardListing;
