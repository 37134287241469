import { useGetApplicationDetailActivityQuery } from "../../../../store/queries/Report";
import { useParams } from "react-router-dom";

const useActivityDetails = () => {
  //getting applicant id from params
  const { applicant_id } = useParams();
  //getting applicant id from session storage
  const active = sessionStorage.getItem("active");

  const { data: ActivityData, isFetching } =
    useGetApplicationDetailActivityQuery({
      applicant_id: +active ?? +applicant_id,
    });

  // const testApplicantData = {
  //   data: [
  //     {
  //       title: "Process start",
  //       is_active: false,
  //       is_completed: true,
  //     },
  //     {
  //       title: "Pending Document Verification",
  //       is_active: false,
  //       is_completed: true,
  //       data: [
  //         {
  //           title: "payment_verified_using_common_banck_holders_name",
  //         },
  //         {
  //           title: "payment_verified_using_common_banck_holders_name",
  //         },

  //         {
  //           title: "payment_verified_using_common_banck_holders_name",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Manually Completed Verification",
  //       is_active: false,
  //       is_completed: true,
  //       data: [
  //         {
  //           title: "documents_manually_approved",
  //           user: {
  //             first_name: "ihsan",
  //             lst_name: "",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       title: "Manually Rejected",
  //       is_active: false,
  //       is_completed: true,
  //       data: [
  //         {
  //           title: "documets_manually_rejected",
  //           user: {
  //             first_name: "ihsan",
  //             lst_name: "",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       title: "Manually Completed Verification",
  //       is_active: false,
  //       is_completed: true,
  //       data: [
  //         {
  //           title: "documents_manually_approved",
  //           user: {
  //             first_name: "ihsan",
  //             lst_name: "",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       title: "Manually Rejected",
  //       is_active: false,
  //       is_completed: true,
  //       data: [
  //         {
  //           title: "documets_manually_rejected",
  //           user: {
  //             first_name: "ihsan",
  //             lst_name: "",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       title: "Manually Completed Verification",
  //       is_active: true,
  //       is_completed: true,
  //       data: [
  //         {
  //           title: "documents_manually_approved",
  //           user: {
  //             first_name: "ihsan",
  //             lst_name: "",
  //           },
  //         },
  //       ],
  //     },
  //   ],
  //   status_code: 200,
  // };

  // console.log(testApplicantData, "activityData");

  return { ActivityData, isFetching };
};

export default useActivityDetails;
