import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Report/bookingReportSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetBookingReportDataQuery,
  useUpdateBookingReportTableHeadDataMutation,
} from "../../../store/queries/Report";

const useBookingReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const bookingReportState = useSelector((state) => state.bookingReport);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: bookingReportList,
    isLoading,
    isFetching,
    refetch,
  } = useGetBookingReportDataQuery({
    search: bookingReportState?.search,
    fields: bookingReportState?.fields,
    page_number: bookingReportState?.currentPage,
    paginator_limit: bookingReportState?.currentPageSize,
  });
  const [updateBookingReportTableHead] =
    useUpdateBookingReportTableHeadDataMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };
  useEffect(() => {
    if (bookingReportState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [bookingReportState?.clearSelection]);
  const handleSort = (label) => {
    if (bookingReportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            bookingReportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    // dispatch(getStaffEditData(e?.[0]));
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    showEditModal,
    bookingReportList,
    paginationOptions,
    bookingReportState,
    updateBookingReportTableHead,
    handleCreateClick,
    handleDeleteModal,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeEditModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useBookingReport;
