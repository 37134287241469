import React from "react";
import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import usePendingApplicants from "./usePendingApplicants";
import { FaSort } from "react-icons/fa";

const PendingApplicants = () => {
  const {
    pendingApplicantList,
    pendingApplicantState,
    isLoading,
    isFetching,
    paginationOptions,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  } = usePendingApplicants();

  return (
    <>
      <HeadingGroup title={"Pending Applicants"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                pendingApplicantState?.search === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: pendingApplicantState?.search }}
              showActions={false}
            />
          </div>
        </div>
        {pendingApplicantList?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={pendingApplicantList?.data || []}
            uniqueID={"id"}
            fields={pendingApplicantList?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={pendingApplicantState?.clearSelection}
            loading={isFetching}
            perpage={pendingApplicantState?.currentPageSize}
            assignable={false}
            //   handleEdit={handleEditAction}
            //   handleDelete={handleDeleteModal}
            deletable={true}
            editable={true}
            showCheckBox={false}
          />
        )}

        {pendingApplicantList?.data?.length > 0 && (
          <div>
            <Pagination
              currentPage={pendingApplicantState?.currentPage}
              totalPageCount={pendingApplicantList?.number_of_pages}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === pendingApplicantState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PendingApplicants;
