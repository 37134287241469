import { getAdminAxiosInstance } from "../../../api";

export const excessReport = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`download-excess-amount-list`, body, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
