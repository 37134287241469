import React from "react";
import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useRefundTransaction from "./useRefundTransaction";

const RefundTransaction = () => {
  const {
    refundTransactionList,
    refundTransactionState,
    isLoading,
    isFetching,
    paginationOptions,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  } = useRefundTransaction();
  return (
    <>
      <HeadingGroup title={"Refund Transactions"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-5">
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined">search</span>
              }
              showClearFilters={
                refundTransactionState?.search === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: refundTransactionState?.search }}
              showActions={false}
            />
          </div>
        </div>
        {refundTransactionList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={refundTransactionList?.data?.data || []}
            uniqueID={"id"}
            fields={refundTransactionList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={refundTransactionState?.clearSelection}
            loading={isFetching}
            perpage={refundTransactionState?.currentPageSize}
            assignable={false}
            //   handleEdit={handleEditAction}
            //   handleDelete={handleDeleteModal}
            deletable={true}
            editable={true}
            showCheckBox={false}
          />
        )}

        {refundTransactionList?.data?.data?.length > 0 && (
          <div>
            <Pagination
              currentPage={refundTransactionState?.currentPage}
              totalPageCount={refundTransactionList?.data?.number_of_pages}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === refundTransactionState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RefundTransaction;
