import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { forgotPassword, login } from "../api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";

const useLogin = (emailRef) => {
  const [showPassword, setShowPassword] = useState(true);
  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("idle");
  const [forgotPasswordInfo, setForgotPasswordInfo] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("USER_ACCESS_TOKEN");
  useEffect(() => {
    localStorage?.removeItem("PASSWORD_CHANGE_TOKEN");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("is_superadmin");
    localStorage.removeItem("USER_DETAILS");
    localStorage.clear();
    sessionStorage.clear();
    emailRef.current = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginStatus === "success") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
      if (accessToken) {
        navigate("/dashboard");
      }
      // window.location.reload();
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  useEffect(() => {
    if (forgotPasswordStatus === "success") {
      navigate("/login/forgot-password");
    } else if (forgotPasswordStatus === "failed") {
      let errorFields = Object.keys(forgotPasswordInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, forgotPasswordInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [forgotPasswordStatus]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email / Employee ID Required";
      }
      if (!values.password) {
        errors.password = "*Password Required";
      }
      // if (!validateEmail(values.email)) {
      //   errors.email = "*Enter a valid email";
      // }
      return errors;
    },
    onSubmit: (values) => {
      emailRef.current = values?.email;
      setLoginStatus("pending");
      login(values).then((response) => {
        if (response?.status === 200) {
          localStorage.setItem(`USER_ACCESS_TOKEN`, response?.data?.access);
          localStorage.setItem("is_admin", response?.data?.is_admin);
          localStorage.setItem("is_superadmin", response?.data?.is_super_admin);
          const userDetails = {
            firstName: response?.data?.first_name,
            email: response?.data?.email,
          };

          localStorage.setItem("USER_DETAILS", JSON.stringify(userDetails));
          setLoginStatus("success");
        } else if (response?.status_code === 401) {
          setLoginStatus("failed");
          setLoginInfo({ password: response?.detail });
          toast.error(response?.message);
        } else {
          setLoginStatus("An error occurred");
          setLoginInfo({ password: response?.detail });
          toast.error(response?.message ?? "failed to login");
        }
      });
    },
  });

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleForgotPassword = () => {
    localStorage.removeItem("USER_ACCESS_TOKEN");
    emailRef.current = formik.values.email;
    formik?.setFieldError("password", "");
    if (!formik.values.email) {
      formik.setFieldError("email", "*Provide an email to reset password");
      toast.error("*Provide an email to reset password");
    } else if (!formik.errors.email && formik.values.email) {
      setForgotPasswordStatus("pending");
      forgotPassword({ email: formik.values.email }).then((response) => {
        if (response?.data?.status_code === 200) {
          setForgotPasswordStatus("success");
          dispatch(
            updateConfig((state) => {
              state.otpGenerated = response?.data?.status;
            })
          );
        } else if (response?.status_code === 404) {
          setForgotPasswordInfo({
            email: response?.error,
          });
          setForgotPasswordStatus("failed");
        } else {
          setForgotPasswordInfo({
            email: "something went wrong",
          });
          setForgotPasswordStatus("failed");
        }
      });
    }
  };

  return {
    formik,
    loginStatus,
    showPassword,
    forgotPasswordStatus,
    setForgotPasswordStatus,
    handleForgotPassword,
    handleShowPassword,
  };
};

export default useLogin;
