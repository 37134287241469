import { useState } from "react";
import { user } from "../../../../store/queries/User";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";
import { report } from "../../../../store/queries/Report";

const useHeaderActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.global);
  const [isProfileViewVisible, setProfileViewVisible] = useState(false);

  const handleViewProfile = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = !state.isProfileViewVisible;
      })
    );
  };

  const handleLogout = () => {
    dispatch(report.util.resetApiState());
    dispatch(user.util.resetApiState());
    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    navigate("/");
  };
  const handleSettings = () => {
    navigate(`/settings/appearance`);
  };

  return {
    globalState,
    isProfileViewVisible,
    handleLogout,
    handleSettings,
    handleViewProfile,
    setProfileViewVisible,
  };
};

export default useHeaderActions;
