import { getAdminAxiosInstance } from "../../../api";

export const addUser = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post("account/api-create-user", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateUser = async (body) => {
  
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.put("account/api-update-user", body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};


